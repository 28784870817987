import { useDispatch, useSelector } from 'react-redux'
import { WEBSOCKET_URL, alerts } from '../../../utils/constants'
import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react'
import {
  StyledMenu,
  findExistingAlertIndex,
} from '../../../utils/helperFunctions'
import {
  setPatientUHID,
  setCurrentLocation,
  setSelectedPatientIndex,
} from '../../../redux/actions'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import NotificationsIcon from '@mui/icons-material/Notifications'
import useGetPatientDetails from '../../../utils/useGetPatientDetails'
import useSound from 'use-sound'
import './VitalAlerts.css'

const VitalAlerts = (): ReactElement => {
  const alertSound = require('../../../assets/AlertSound/alertSound.mp3')
  const [play] = useSound(alertSound, { volume: 0.2 })
  const { fetchPatientDetails } = useGetPatientDetails()
  const patientDetails = useSelector((state: any) => ({
    masterPatientDetails: state.masterPatientDetails,
  }))
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true)
  const [alertData, setAlertData] = useState<any[]>([])
  const [alertClicked, setAlertClicked] = useState(
    alertData?.filter((alert) => !alert?.isClicked)?.length
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const alertSocketRef = useRef<WebSocket | null>(null)
  const dispatch = useDispatch()

  const open = Boolean(anchorEl)

  const handleAlertBtnClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget)
  }

  const createSocketConnection = () => {
    const socket = new WebSocket(
      `${WEBSOCKET_URL}websocket?deviceId=alert&patientId=alert`
    )
    alertSocketRef.current = socket

    socket.onopen = function (event) {
      console.log('WebSocket connection', event.type)
    }

    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data)
        if (message?.alarm_data) {
          const formattedAlertData = {
            muhid: message?.patient_id,
            location: patientDetails?.masterPatientDetails
              ?.flat()
              ?.find((item) => item?.patientId === message?.patient_id)
              ?.location,
            vitals: message?.alarm_data?.[1]?.alarm_type,
            isClicked: false,
            count: 1,
            value: message?.alarm_data?.[1]?.alarm_value,
            value_limit: message?.alarm_data?.[0]?.alarm_value,
          }

          const handleUpdateAlertData = (prevAlertData, formattedAlertData) => {
            const existingAlertIndex = findExistingAlertIndex(
              prevAlertData,
              formattedAlertData
            )

            if (existingAlertIndex !== -1) {
              const updatedAlertData = [...prevAlertData]
              updatedAlertData[existingAlertIndex].count += 1
              return updatedAlertData
            } else {
              return [...prevAlertData, formattedAlertData]
            }
          }

          setAlertData((prevAlertData) =>
            handleUpdateAlertData(prevAlertData, formattedAlertData)
          )
        }
      } catch (error) {
        console.error('Error parsing message data:', error)
      }
    }

    socket.onerror = function (event) {
      console.error('WebSocket error observed:', event)
    }

    socket.onclose = function (event) {
      console.log('Websocket closure code:', event.code)
      if (event.code !== 1000 && event.code !== 1001) {
        console.log(
          'Websocket closed abnormally. Reconnecting to WebSocket server...'
        )
        createSocketConnection()
      }
    }
  }

  const closeWebSocket = () => {
    if (alertSocketRef?.current) {
      alertSocketRef?.current?.close()
      console.log('WebSocket connection closed')
    }
  }

  useEffect(() => {
    setAlertClicked(alertData?.filter((alert) => !alert?.isClicked)?.length)
  }, [alertData])

  useEffect(() => {
    createSocketConnection()
    return () => {
      closeWebSocket()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false)
      return
    }
    play()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertData, isInitialRender])

  const handleAlertItemClick = (
    index: number,
    muhid: string,
    location: string
  ) => {
    const patientsList = patientDetails?.masterPatientDetails?.find(
      (subArray) => subArray?.some((patient) => patient?.patientId === muhid)
    )
    const updatedAlertData = alertData?.map((alert, i) => {
      if (i === index) {
        return { ...alert, isClicked: true }
      }
      return alert
    })

    setAlertData(updatedAlertData)
    setAnchorEl(null)
    if (
      patientDetails?.masterPatientDetails
        ?.flat()
        ?.some((patient) => patient?.patientId === muhid)
    ) {
      fetchPatientDetails(muhid)
      dispatch(setPatientUHID(muhid))
      dispatch(setCurrentLocation(location))
      const patientIndex = patientsList?.findIndex(
        (item) => item?.patientId === muhid
      )
      dispatch(setSelectedPatientIndex(patientIndex))
    } else {
      alert(alerts?.alertsError)
    }
  }

  const getAlarmValue = (alarmValue) => {
    if (alarmValue === alerts?.higherThanLimit) return alerts?.high
    else if (alarmValue === alerts?.lowerThanLimit) return alerts?.low
    else return alarmValue
  }

  const removeAlert = (e, index) => {
    e.stopPropagation()
    const updatedAlertData = alertData?.filter((_, i) => i !== index)
    setAlertData(updatedAlertData)
  }

  return (
    <Box display='flex' justifyContent='center' paddingX='20px' marginY={1}>
      {alertData?.length > 0 ? (
        <div id='alerts-appeared'>
          <Button
            className='alert-button'
            id='vitals-alert-button'
            aria-controls={open ? 'alerts-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            variant='contained'
            disableElevation
            onClick={handleAlertBtnClick}
          >
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              badgeContent={alertClicked}
              color='error'
            >
              <NotificationsIcon fontSize='medium' />
            </Badge>
          </Button>
          <StyledMenu
            id='alerts-menu'
            MenuListProps={{
              'aria-labelledby': 'vitals-alert-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
          >
            {alertData?.map((item, index) => {
              return (
                <MenuItem
                  key={item?.muhid}
                  id={crypto.randomUUID()}
                  className={`alert-details-container ${
                    item?.isClicked ? 'clicked' : ''
                  }`}
                >
                  <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    badgeContent={item?.count}
                    color='primary'
                    className='alert-badge'
                  ></Badge>
                  <Box
                    className='alert-details'
                    onClick={() =>
                      handleAlertItemClick(index, item?.muhid, item?.location)
                    }
                  >
                    <Box>
                      <Typography className='alert-subDetails' variant='body2'>
                        {item?.muhid}
                      </Typography>
                      <Typography className='alert-subDetails' variant='body2'>
                        {item?.location}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='body2' className='alert-vitals'>
                        {item?.vitals}&nbsp;:&nbsp;{item?.value}
                      </Typography>
                    </Box>
                    <Box>
                      {item?.value_limit && (
                        <Typography
                          variant='body2'
                          className={
                            item?.value_limit === alerts?.higherThanLimit ||
                            item?.value_limit === alerts?.lowerThanLimit
                              ? 'alert-vitals-for-normal'
                              : 'alert-vitals-for-extreme'
                          }
                        >
                          {getAlarmValue(item?.value_limit)}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <IconButton
                    aria-label='remove-alert'
                    onClick={(e) => removeAlert(e, index)}
                  >
                    <CloseIcon className='close-icon' />
                  </IconButton>
                </MenuItem>
              )
            })}
          </StyledMenu>
        </div>
      ) : null}
    </Box>
  )
}

export default VitalAlerts
