import { withStyles } from '@mui/styles'
import { lightningChart } from '@lightningchart/lcjs'
import { ReactElement, SyntheticEvent } from 'react'
import { styled, alpha } from '@mui/material/styles'
import Menu, { MenuProps } from '@mui/material/Menu'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { ambulanceText, treatmentAndMedicationsMessages } from './constants'
import {
  Encounter,
  ProrithmVital,
  ProrithmBpVital,
  FormattedAlertData,
} from '../interfaces'
import TableRow from '@mui/material/TableRow'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

export const lc = lightningChart({
  license: process.env.REACT_APP_LIGHTNING_CHARTS_LICENSE,
  licenseInformation: {
    appTitle: process.env.REACT_APP_CHARTS_APP_TITLE ?? '',
    company: process.env.REACT_APP_CHARTS_COMPANY ?? '',
  },
  sharedContextOptions: {
    useIndividualCanvas: false,
  },
})

export const createApiUrl = (projectId: string | undefined): string => {
  if (!projectId) return ''
  return process.env.REACT_APP_SOCKET_URL + 'api/projectId/' + projectId
}

export const createFormApiUrl = (projectId: string | undefined): string => {
  if (!projectId) return ''
  return process.env.REACT_APP_SOCKET_URL + 'form/projectId/' + projectId
}

export const calculateAge = (dateOfBirth: string): string | number => {
  if (!dateOfBirth) return ''
  const birthdateObj = new Date(dateOfBirth)
  const currentDate = new Date()

  let ageDiff = currentDate.getFullYear() - birthdateObj.getFullYear()

  if (
    currentDate.getMonth() < birthdateObj.getMonth() ||
    (currentDate.getMonth() === birthdateObj.getMonth() &&
      currentDate.getDate() < birthdateObj.getDate())
  ) {
    ageDiff--
  }
  return ageDiff
}

export function dateDifference(startdate: string, enddate: string): number {
  if (!startdate || !enddate) return 0
  const start = new Date(startdate)
  const end = new Date(enddate)

  if (start > end) {
    return 0
  }

  const diffInMs = end.getTime() - start.getTime()
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

  return Math.floor(diffInDays)
}

export function formatReadableDate(datetimeStr: string): string {
  const dateObj = new Date(datetimeStr)
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }
  const readableDate = dateObj.toLocaleDateString(undefined, dateOptions)
  const readableTime = dateObj.toLocaleTimeString(undefined, timeOptions)

  if (!datetimeStr) {
    return 'Invalid date'
  }

  if (isNaN(dateObj.getTime())) {
    return 'Invalid date'
  }

  return `${readableDate} ${readableTime}`
}

export function getDate(datetimeStr: string): string {
  const dateObj = new Date(datetimeStr)
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const readableDate = dateObj.toLocaleDateString(undefined, dateOptions)

  if (!datetimeStr) {
    return 'Invalid date'
  }
  if (isNaN(dateObj.getTime())) {
    return 'Invalid date'
  }
  return `${readableDate}`
}

export const getCurrentDate = (): string => {
  const current = new Date()
  const year = current.getFullYear()
  const month = String(current.getMonth() + 1).padStart(2, '0')
  const day = String(current.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const getCurrentDateAndTime = (): string => {
  const currentDate = new Date()
  const utcDate = currentDate.toISOString()
  return utcDate
}

export const formatDate = (date: Date | null): string => {
  if (!date) return ''

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export function titleCase(str: string): string {
  if (!str) return ''
  return str?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0)?.toUpperCase() + txt?.substring(1)?.toLowerCase()
  })
}

export function filterEncountersByDate(
  data: Encounter[],
  targetDate: Date | null
): Encounter[] {
  if (!data || targetDate === null) {
    return data || []
  }

  const normalizedTargetDate = new Date(targetDate)
  normalizedTargetDate.setHours(0, 0, 0, 0)

  return data?.filter((encounter) => {
    if (!encounter?.date) {
      return false
    }
    const encounterDate = new Date(encounter.date)
    encounterDate.setHours(0, 0, 0, 0)

    return encounterDate.getTime() === normalizedTargetDate.getTime()
  })
}

export const calculateFreq = (
  frequency: string,
  dose: number,
  durations: string,
  interval: number
): { quantities: number | undefined; unitsOne: string | undefined } => {
  const duration = parseInt(durations)
  let quantities
  let unitsOne

  if (frequency === treatmentAndMedicationsMessages?.immediately) {
    quantities = dose * 1 * duration
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === treatmentAndMedicationsMessages?.everyHour) {
    quantities = dose * duration
    unitsOne = 'hour'
  } else if (frequency === treatmentAndMedicationsMessages?.everyTwoHour) {
    quantities = Math.round(dose * (duration / 2))
    unitsOne = 'hour'
  } else if (frequency === treatmentAndMedicationsMessages?.everyThreeHour) {
    quantities = Math.round(dose * (duration / 3))
    unitsOne = 'hour'
  } else if (frequency === treatmentAndMedicationsMessages?.everyFourHour) {
    quantities = dose * 6 * duration
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === treatmentAndMedicationsMessages?.everySixHour) {
    quantities = dose * 4 * duration
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === treatmentAndMedicationsMessages?.everyEightHour) {
    quantities = Math.round(dose * (duration / 8))
    unitsOne = 'hour'
  } else if (frequency === treatmentAndMedicationsMessages?.everyTwelveHour) {
    quantities = Math.round(dose * (duration / 12))
    unitsOne = 'twelveHour'
  } else if (frequency === treatmentAndMedicationsMessages?.twoWeek) {
    quantities = Math.round(dose * (duration / 2))
    unitsOne = treatmentAndMedicationsMessages?.twoWeek
  } else if (frequency === 'Every 3 weeks') {
    quantities = Math.round(dose * (duration / 3))
    unitsOne = '3 week'
  } else if (frequency === treatmentAndMedicationsMessages?.onceADay) {
    quantities = dose * 1 * duration
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === treatmentAndMedicationsMessages?.twiceADay) {
    quantities = dose * 2 * duration
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === treatmentAndMedicationsMessages?.thriceADay) {
    quantities = dose * 3 * duration
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === treatmentAndMedicationsMessages?.fourTimesADay) {
    quantities = dose * 4 * duration
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === treatmentAndMedicationsMessages?.onAlternateDays) {
    quantities = Math.round(dose * interval)
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === treatmentAndMedicationsMessages?.onceAWeek) {
    quantities = dose * duration
    unitsOne = treatmentAndMedicationsMessages?.week
  } else if (frequency === treatmentAndMedicationsMessages?.fiveTimesADay) {
    quantities = dose * duration * 5
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === 'twoDayWeek') {
    quantities = Math.ceil((2 / 7) * dose * duration)
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === treatmentAndMedicationsMessages?.twiceAWeek) {
    quantities = 2 * dose * duration
    unitsOne = treatmentAndMedicationsMessages?.week
  } else if (frequency === treatmentAndMedicationsMessages?.threeDaysAWeek) {
    quantities = 3 * dose * duration
    unitsOne = treatmentAndMedicationsMessages?.week
  } else if (frequency === treatmentAndMedicationsMessages?.fourDaysAWeek) {
    quantities = 4 * dose * duration
    unitsOne = treatmentAndMedicationsMessages?.week
  } else if (frequency === treatmentAndMedicationsMessages?.fiveDaysAWeek) {
    quantities = 5 * dose * duration
    unitsOne = treatmentAndMedicationsMessages?.week
  } else if (frequency === treatmentAndMedicationsMessages?.sixDaysAWeek) {
    quantities = 6 * dose * duration
    unitsOne = treatmentAndMedicationsMessages?.week
  } else if (frequency === treatmentAndMedicationsMessages?.day) {
    quantities = dose * duration
    unitsOne = treatmentAndMedicationsMessages?.day
  } else if (frequency === treatmentAndMedicationsMessages?.week) {
    quantities = dose * duration * 7
    unitsOne = treatmentAndMedicationsMessages?.week
  } else if (frequency === treatmentAndMedicationsMessages?.month) {
    quantities = dose * duration * 30
    unitsOne = treatmentAndMedicationsMessages?.month
  }

  return { quantities, unitsOne }
}

export const addDays = (
  date: string | number | Date,
  days: number
): Date | null => {
  if (isNaN(new Date(date).getTime())) return null

  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export function generateRandomID(length: number): string {
  if (!length) return ''

  const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const numbers = '0123456789'
  const characters = alphabets + numbers

  let result = ''

  result += alphabets.charAt(
    crypto.getRandomValues(new Uint8Array(1))[0] % alphabets.length
  )
  result += numbers.charAt(
    crypto.getRandomValues(new Uint8Array(1))[0] % numbers.length
  )

  for (let i = 2; i < length; i++) {
    result += characters.charAt(
      crypto.getRandomValues(new Uint8Array(1))[0] % characters.length
    )
  }

  return result
    ?.split('')
    ?.sort(() => 0.5 - crypto.getRandomValues(new Uint8Array(1))[0])
    ?.join('')
}

export const checkRanges = (systolic: number, diastolic: number): boolean => {
  if (systolic == null || diastolic == null) return false
  return (
    systolic >= 90 && systolic <= 150 && diastolic >= 40 && diastolic <= 100
  )
}

export function getSystolicBpValue(bp: number | undefined): number {
  if (bp === undefined) {
    return 40
  }
  if (bp < 40) {
    return 40
  } else if (bp > 200) {
    return 200
  }
  return bp
}

export function getDiastolicBpValue(bp: number | undefined): number {
  if (bp === undefined) {
    return 0
  }
  if (bp < 0) {
    return 0
  } else if (bp > 170) {
    return 170
  }
  return bp
}

export const getValidSystolicBp = (
  systolicBpValue: number,
  arterialSys: number
): string | number => {
  if (systolicBpValue > 0) {
    return systolicBpValue
  }
  if (arterialSys > 0) {
    return arterialSys
  }
  return '-'
}

export const getValidDiastolicBp = (
  diastolicBpValue: number,
  arterialDia: number
): string | number => {
  if (diastolicBpValue > 0) {
    return diastolicBpValue
  }
  if (arterialDia > 0) {
    return arterialDia
  }
  return '-'
}

export const setVitalValue = (
  message: ProrithmVital[],
  setValue: (val) => void
): void => {
  if (message) {
    const val =
      message?.[0]?.v === ambulanceText?.lenError ? '-' : message?.[0]?.v
    setValue(val)
  }
}

export const setSkinTempValue = (
  message: ProrithmVital[],
  setSkinTemp: (skt) => void
): void => {
  if (message) {
    const skt =
      message?.[0]?.v === ambulanceText?.lenError ? 90 : message?.[0]?.v
    setSkinTemp(skt)
  }
}

export const setBpValue = (
  message: ProrithmBpVital[],
  setSystolicBP: (sysBp) => void,
  setDiastolicBP: (diaBp) => void
): void => {
  if (message) {
    const sysBp =
      message?.[0]?.sp === ambulanceText?.lenError ? 40 : message?.[0]?.sp
    const diaBp =
      message?.[0]?.dp === ambulanceText?.lenError ? 0 : message?.[0]?.dp
    setSystolicBP(sysBp)
    setDiastolicBP(diaBp)
  }
}

const isMatchingAlert = (alert, formattedAlertData): boolean => {
  return (
    alert?.muhid === formattedAlertData?.muhid &&
    alert?.vitals === formattedAlertData?.vitals
  )
}

export const findExistingAlertIndex = (
  prevAlertData: any[],
  formattedAlertData: FormattedAlertData
): number => {
  return prevAlertData?.findIndex((alert) =>
    isMatchingAlert(alert, formattedAlertData)
  )
}

export function findPatientIndex(
  prevList: any[],
  mrNumber: string | undefined
): number {
  return prevList?.findIndex((patient) => patient?.mrNumber === mrNumber)
}

export const icon: ReactElement = <CheckBoxOutlineBlankIcon fontSize='small' />
export const checkedIcon: ReactElement = <CheckBoxIcon fontSize='small' />
export const action = (
  handleClose: (event: SyntheticEvent | Event, reason?: string) => void
): ReactElement => (
  <IconButton
    id='close-button-for-snackbar'
    size='small'
    aria-label='close'
    color='inherit'
    onClick={handleClose}
  >
    <CloseIcon id='close-icon' fontSize='small' />
  </IconButton>
)

export const CustomArrowIcon = withStyles({
  root: {
    color: '#1b5299',
  },
})(ArrowDropDownIcon)

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(0),
    minWidth: 180,
    maxHeight: 230,
    paddingLeft: 5,
    paddingRight: 5,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}))
