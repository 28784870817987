import { Box } from '@mui/material'
import { MutableRefObject, FC } from 'react'
import { CriticalBeds } from '../../../interfaces'
import { admDate } from '../../../utils/constants'
import { titleCase, getDate } from '../../../utils/helperFunctions'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import './PatientCard.css'

interface PatientCardProps {
  patientRefs: MutableRefObject<(HTMLDivElement | null)[]>
  index: number
  selectedPatientIndex: number
  handleSelectedPatient: (patient: CriticalBeds, index: number) => void
  patient: CriticalBeds
}

export const PatientCard: FC<PatientCardProps> = ({
  patientRefs,
  index,
  selectedPatientIndex,
  handleSelectedPatient,
  patient,
}) => {
  return (
    <Box
      id={crypto.randomUUID()}
      ref={(el: HTMLDivElement | null) => (patientRefs.current[index] = el)}
      className={`patient-card ${
        index === selectedPatientIndex ? 'patient-card-selected' : ''
      }`}
      onClick={() => handleSelectedPatient(patient, index)}
    >
      <Box marginX='3px' marginY='3px'>
        <AccountCircleIcon className='patient-logo' fontSize='large' />
      </Box>
      <Box marginX='5px' marginY='0px'>
        <Box typography='body2'>
          {titleCase(patient?.patient_name)}&nbsp;-&nbsp;
          {patient?.patient_id}
        </Box>
        <Box typography='subtitle2'>
          {admDate}:&nbsp;
          {getDate(patient?.admission_date)}
        </Box>
      </Box>
    </Box>
  )
}
