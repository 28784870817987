export const CONSTANTS = {
  FIRST_NAME: 'first-name',
  LAST_NAME: 'last-name',
  USER_ID: 'user-id',
  REACT_TOKEN: 'react-token',
}

export const AUTH_SERVER_URL = process.env.REACT_APP_KEYCLOAK_URL ?? ''
export const CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? ''
export const REALM = process.env.REACT_APP_KEYCLOAK_REALM ?? ''
export const DMS_API_URL = process.env.REACT_APP_DMS_API_URL
export const CRITICAL_BEDS_ID = process.env.REACT_APP_CRITICAL_BEDS
export const IPD_PROJECT_ID = process.env.REACT_APP_IPD_PROJECT_ID
export const OPD_PROJECT_ID = process.env.REACT_APP_OPD_PROJECT_ID
export const LABS_PROJECT_ID = process.env.REACT_APP_LABS_PROJECT_ID
export const CHAT_PROJECT_ID = process.env.REACT_APP_CHAT_PROJECT_ID
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL
export const APP_TYPE = process.env.REACT_APP_SOCKET_APP_TYPE
export const INTENSIVIST_DOCTOR_ID = process.env.REACT_APP_INTENSIVIST_DOCTOR_ID
export const JITSI_SECRET_KEY = process.env.REACT_APP_JITSI_SECRET_KEY
export const JITSI_APP_ID = process.env.REACT_APP_JITSI_APP_ID ?? ''

export const noPatientAvailableText =
  'No patients found for your search query/location.'
export const naText = 'N/A'
export const actionPanel = 'Action Panel'
export const treatmentAndMedications = 'Treatment & Medications'
export const notInList = 'Not in List'
export const oneQuantityText = 'Qty-1'
export const keepEditingText = 'Keep Editing'
export const submit = 'Submit'
export const years = 'Years'
export const enterFullScreen = 'Enter Full Screen'
export const exitFullscreen = 'Exit Full Screen'
export const tests = 'Tests'
export const doctor = 'Doctor'
export const careplanHistory = 'Careplan History'
export const medications = 'Medications'
export const labSuggestionsText = 'Treatment Suggestions'
export const noMedications = 'No Medications found'
export const admDate = 'Adm.Date'
export const arrived = 'arrived'
export const intensivistCommandCenter = 'Intensivist Command Centre'
export const careplan = 'careplan'
export const careplanCreatedSuccessfully = 'Careplan created successfully'
export const onHold = 'ONHOLD'
export const noDataToShow = 'No data to show'
export const inactive = 'INACTIVE'
export const medicationSubmitAlert =
  'Review and confirm the details before submission'
export const rejectionReason = 'Reason Of Rejection'
export const patientDetailsFailure =
  'Failed to get patient details, Please try again.'
export const yesText = 'yes'

export const positiveDecimalNumberRegex =
  /^(?!0(\.0+)?$)(0?\.\d+|[1-9]\d*(\.\d+)?)$/
export const imageValidationRegex = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i
export const positiveIntegerRegex = /^[1-9]\d*$/
export const positiveMultipleOfDotFiveRegex =
  /^(?!0(\.0+)?$)(0?\.([05])|[1-9]\d*(\.\d+)?)$/

export const intent = {
  ipdDiagnosis: 'ipdDiagnosis',
  addToStaging: 'addToStaging',
  getCarePlans: 'getCarePlans',
  getDoctorsAPI: 'getDoctorsAPI',
  getDrugsByName: 'getDrugsByName',
  getAllPanelsApi: 'getAllPanelsApi',
  getLocationsAPI: 'getLocationsAPI',
  createVisitForCC: 'createVisitForCC',
  getPatientReports: 'getPatientReports',
  getPatientDetails: 'getPatientDetails',
  getWardsByLocation: 'getWardsByLocation',
  getLabOrdersForIPD: 'getLabOrdersForIPD',
  createNotification: 'createNotification',
  editMedicationById: 'editMedicationById',
  treatmentPlanForIPD: 'treatmentPlanForIPD',
  getAllSubEncounters: 'getAllSubEncounters',
  getDocIdForLabOrder: 'getDocIdForLabOrder',
  getallVisitmedication: 'getallVisitmedication',
  getDrugsByComposition: 'getDrugsByComposition',
  fetchObservationsForCC: 'fetchObservationsForCC',
  getEncounterAndDocName: 'getEncounterAndDocName',
  getCriticalBedsByLocation: 'getCriticalBedsByLocation',
  getMedicineAdministration: 'getMedicineAdministration',
  getAdministerMedicineById: 'getAdministerMedicineById',
  getBedDetailsByLocationAndWards: 'getBedDetailsByLocationAndWards',
}

export const patientDemographicData = {
  age: 'Age',
  name: 'Name',
  uhid: 'UHID',
  bed: 'Bed No.',
  room: 'Room No.',
  gender: 'Gender',
  ward: 'Ward Name',
}

export const location = {
  amalapuram: 'Amalapuram',
  malkipuram: 'Malkipuram',
  rajahmundry: 'Rajahmundry',
  kimsAmalapuram: 'KIMS Amalapuram',
}

export const treatmentAndMedicationsMessages = {
  na: 'N/A',
  startDate: 'startDate',
  continuous: 'Continuous',
  week: 'week',
  day: 'day',
  month: 'month',
  twoWeek: '2 week',
  careplanDetails: 'Careplan details',
  careplanSuccess: 'Careplan created successfully',
  frequencySelectedOnceADay: 'Frequency selected as ONCE A DAY',
  frequencySelectedOnceAWeek: 'Frequency selected as ONCE A WEEK',
  frequencySelectedTwiceADay: 'Frequency selected as TWICE A DAY',
  frequencySelectedTwiceAWeek: 'Frequency selected as TWICE A WEEK',
  frequencySelectedThriceADay: 'Frequency selected as THRICE A DAY',
  frequencySelectedThreeDaysAWeek: 'Frequency selected as THREE DAYS A WEEK',
  frequencySelectedFourTimesADay: 'Frequency selected as FOUR TIMES A DAY',
  frequencySelectedFourDaysAWeek: 'Frequency selected as FOUR DAYS A WEEK',
  frequencySelectedFiveTimesADay: 'Frequency selected as FIVE TIMES A DAY',
  frequencySelectedFiveDaysAWeek: 'Frequency selected as FIVE DAYS A WEEK',
  frequencySelectedSixDaysAWeek: 'Frequency selected as SIX DAYS A WEEK',
  positiveWholeNumberAccepted: 'Only positive whole numbers are accepted.',
  greaterthanZeroandMultipleOfFive:
    'Value must be > 0 and must be in multiple of 0.5',
  valueGreaterThanZero: 'Value must be greater than zero.',
  patientIdNotProvided: 'Patient Id is not provided',
  locationNotProvided: 'Location is not provided',
  noDatesProvided: 'No dates provided or dates array is empty',
  unableToCreateCarePlan: 'Unable to create Careplan. Please try again.',
  greaterThanZero: 'Value must be greater than 0 and must be whole number',
  onHold: 'ON HOLD',
  ACCEPTED: 'ACCEPTED',
  PROCESSED: 'PROCESSED',
  REJECTED: 'REJECTED',
  STAGING: 'STAGING',
  accepted: 'Accepted',
  rejected: 'Rejected',
  pending: 'Pending',
  onceADay: 'Once a day',
  twiceADay: 'Twice a day',
  immediately: 'Immediately',
  fourTimesADay: 'Four times a day',
  thriceADay: 'Thrice a day',
  fiveTimesADay: 'Five times a day',
  onAlternateDays: 'On alternate days',
  onceAWeek: 'Once a week',
  twiceAWeek: 'Twice a week',
  threeDaysAWeek: 'Thrice a week',
  fourDaysAWeek: 'Four days a week',
  fiveDaysAWeek: 'Five days a week',
  sixDaysAWeek: 'Six days a week',
  everyHour: 'Every Hour',
  everyTwoHour: 'Every 2 hours',
  everyThreeHour: 'Every 3 hours',
  everyTwoWeeks: 'Every 2 weeks',
  everyThreeWeeks: 'Every 3 weeks',
  everyFourHour: 'Every 4 hours',
  everySixHour: 'Every 6 hours',
  everyEightHour: 'Every 8 hours',
  everyTwelveHour: 'Every 12 hours',
  selectAtLeastOneDate: 'Please select at least one date',
  acceptedTitleCase: 'Accepted',
  days: 'Day(s)',
  weeks: 'Week(s)',
  months: 'Month(s)',
  visitCreationFailure: 'Failed to create a visit',
}

export const treatmentAndMedicationMecalHistory = {
  status: 'Status',
  reason: 'Reason',
  serialNO: 'Sl. No',
  startTimr: 'Start Time',
  actionTime: ' Action Time',
  doctorName: 'Doctor Name',
  details: 'Details',
}

export const patientList = [
  {
    patientId: 'patient1',
    patientName: 'Patient 1',
  },
  {
    patientId: 'patient2',
    patientName: 'Patient 2',
  },
  {
    patientId: 'patient3',
    patientName: 'Patient 3',
  },
  {
    patientId: 'patient4',
    patientName: 'Patient 4',
  },
  {
    patientId: 'patient5',
    patientName: 'Patient 5',
  },
  {
    patientId: 'patient6',
    patientName: 'Patient 6',
  },
  {
    patientId: 'patient7',
    patientName: 'Patient 7',
  },
  {
    patientId: 'patient8',
    patientName: 'Patient 8',
  },
  {
    patientId: 'patient9',
    patientName: 'Patient 9',
  },
  {
    patientId: 'patient10',
    patientName: 'Patient 10',
  },
]

export const ambulanceList = [
  {
    ambulanceId: 'ambulance1',
    ambulanceName: 'Ambulance 1',
  },
  {
    ambulanceId: 'ambulance2',
    ambulanceName: 'Ambulance 2',
  },
  {
    ambulanceId: 'ambulance3',
    ambulanceName: 'Ambulance 3',
  },
  {
    ambulanceId: 'ambulance4',
    ambulanceName: 'Ambulance 4',
  },
  {
    ambulanceId: 'ambulance5',
    ambulanceName: 'Ambulance 5',
  },
  {
    ambulanceId: 'ambulance6',
    ambulanceName: 'Ambulance 6',
  },
  {
    ambulanceId: 'ambulance7',
    ambulanceName: 'Ambulance 7',
  },
  {
    ambulanceId: 'ambulance8',
    ambulanceName: 'Ambulance 8',
  },
  {
    ambulanceId: 'ambulance9',
    ambulanceName: 'Ambulance 9',
  },
  {
    ambulanceId: 'ambulance10',
    ambulanceName: 'Ambulance 10',
  },
]

export const labReports = {
  tat: 'TAT',
  view: 'View',
  test: 'Test',
  slNo: 'S No',
  labId: 'Lab ID',
  status: 'Status',
  doctor: 'Doctor',
  completed: 'COMPLETED',
  requestedBy: 'Requested By',
  dateAndTime: '(Ordered) Date & Time',
  noLabOrdersFound: 'No Lab Orders Found',
  reportUnavailableMessage: 'Report Unavailable! Please contact administrator',
}

export const diagnosis = {
  slNo: 'S No',
  view: 'View',
  date: 'Date',
  close: 'Close',
  noData: 'No Data',
  showLess: 'Show Less',
  showMore: 'Show more',
  selectDate: 'Select Date',
  doctorName: 'Doctor Name',
  finalDiagnosis: 'Final Diagnosis',
  diagnosisDetails: 'Diagnosis Details',
  investigations: 'Investigations/Diagnostics',
  provisionalDiagnosis: 'Provisional Diagnosis',
  differentialDiagnosis: 'Differential Diagnosis',
  intensivistCommandCenter: 'Intensivist Command Centre',
}

export const treatment = {
  yes: 'yes',
  slNo: 'S No',
  view: 'View',
  date: 'Date',
  close: 'Close',
  noData: 'No Data',
  showLess: 'Show less',
  showMore: 'Show more',
  department: 'Department',
  selectDate: 'Select Date',
  doctorName: 'Doctor Name',
  treatmentPlan: 'Treatment Plan',
  reasonReferral: 'Reason Referral',
  nameOfDoctor: 'Name Of the Doctor',
  referralRequired: 'Referral Required',
  treatmentSuggestions: 'Treatment Suggestions',
}

export const assesmentText = {
  date: 'Date',
  slNo: 'S No',
  title: 'Title',
  close: 'Close',
  view: 'Preview',
  noData: 'No Data',
  download: 'Download',
  showLess: 'Show less',
  showMore: 'Show more',
  department: 'Department',
  selectDate: 'Select Date',
  treatmentPlan: 'Treatment Plan',
  reasonReferral: 'Reason Referral',
  nameOfDoctor: 'Name Of the Doctor',
  referralRequired: 'Referral Required',
  treatmentSuggestions: 'Treatment Suggestions',
  dateOfUpload: 'Date Of Upload',
}

export const labReportMedications = {
  no: 'No',
  yes: 'Yes',
  slNo: 'S No',
  dose: 'Dose',
  route: 'Route',
  actions: 'Actions',
  endDate: 'End Date',
  duration: 'Duration',
  drugName: 'Drug Name',
  frequency: 'Frequency',
  intervals: 'Intervals',
  doseUnits: 'Dose Units',
  startDate: 'Start Date',
  discontinue: 'Discontinue',
  addInstructions: 'Add. Instructions',
  discontinueConfirm: 'Are you sure you want to discontinue',
}

export const medicationsTable = {
  dose: 'Dose',
  unit: 'Unit',
  route: 'Route',
  order: 'Order',
  action: 'Action',
  endDate: 'End Date',
  interval: 'Interval',
  totalQty: 'Total Qty',
  frequency: 'Frequency',
  sos: 'SOS/Instructions',
  startDate: 'Start Date',
  duration: 'Duration/Units',
  medicineName: 'Medicine Name',
  searchAlternative: 'Search Alternative',
}

export const unitOptions = [
  { name: 'Capsule(s)', value: 'capsule' },
  { name: 'Tablet(s)', value: 'tablet' },
  { name: 'ml', value: 'ml' },
  { name: 'mg', value: 'mg' },
  { name: 'IU', value: 'iu' },
  { name: 'Drop', value: 'drop' },
  { name: 'Tablespoon', value: 'tablespoon' },
  { name: 'Teaspoon', value: 'teaspoon' },
  { name: 'Unit(s)', value: 'unit' },
  { name: 'Puff(s)', value: 'puff' },
]

export const routeOptions = [
  { name: 'Buccal', value: 'buccal' },
  { name: 'Epidural', value: 'epidural' },
  { name: 'Inhalation', value: 'inhalation' },
  { name: 'Infusion', value: 'infusion' },
  { name: 'Intra-articular', value: 'intra-articular' },
  { name: 'Intracardiac', value: 'intracardiac' },
  { name: 'Intradermal', value: 'intradermal' },
  { name: 'Intraocular', value: 'intraocular' },
  { name: 'Intraosseous', value: 'intraosseous' },
  { name: 'Intraperitoneal', value: 'intraperitoneal' },
  { name: 'Intranasal', value: 'intranasal' },
  { name: 'Intrathecal', value: 'intrathecal' },
  { name: 'Intravaginal', value: 'intravaginal' },
  { name: 'Intravenous', value: 'intravenous' },
  { name: 'Nasogastric', value: 'nasogastric' },
  { name: 'Nasal', value: 'nasal' },
  { name: 'Ophthalmic', value: 'ophthalmic' },
  { name: 'Oral', value: 'oral' },
  { name: 'Otic', value: 'otic' },
  { name: 'Per Rectum', value: 'perrectum' },
  { name: 'Per-Vaginal', value: 'per-vaginal' },
  { name: 'Rectal', value: 'rectal' },
  { name: 'Sub Lingual', value: 'sublingual' },
  { name: 'Subcutaneous', value: 'subcutaneous' },
  { name: 'Topical', value: 'topical' },
  { name: 'Transdermal', value: 'transdermal' },
]

export const frequencyOptions = [
  {
    name: treatmentAndMedicationsMessages?.immediately,
    value: treatmentAndMedicationsMessages?.immediately,
  },
  {
    name: treatmentAndMedicationsMessages?.onceADay,
    value: treatmentAndMedicationsMessages?.onceADay,
  },
  {
    name: treatmentAndMedicationsMessages?.twiceADay,
    value: treatmentAndMedicationsMessages?.twiceADay,
  },
  {
    name: treatmentAndMedicationsMessages?.thriceADay,
    value: treatmentAndMedicationsMessages?.thriceADay,
  },
  {
    name: treatmentAndMedicationsMessages?.fourTimesADay,
    value: treatmentAndMedicationsMessages?.fourTimesADay,
  },
  {
    name: treatmentAndMedicationsMessages?.everyFourHour,
    value: treatmentAndMedicationsMessages?.everyFourHour,
  },
  {
    name: treatmentAndMedicationsMessages?.everySixHour,
    value: treatmentAndMedicationsMessages?.everySixHour,
  },
  {
    name: treatmentAndMedicationsMessages?.onAlternateDays,
    value: treatmentAndMedicationsMessages?.onAlternateDays,
  },
  {
    name: treatmentAndMedicationsMessages?.onceAWeek,
    value: treatmentAndMedicationsMessages?.onceAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.twiceAWeek,
    value: treatmentAndMedicationsMessages?.twiceAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.threeDaysAWeek,
    value: treatmentAndMedicationsMessages?.threeDaysAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.fourDaysAWeek,
    value: treatmentAndMedicationsMessages?.fourDaysAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.fiveDaysAWeek,
    value: treatmentAndMedicationsMessages?.fiveDaysAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.sixDaysAWeek,
    value: treatmentAndMedicationsMessages?.sixDaysAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.continuous,
    value: treatmentAndMedicationsMessages?.continuous,
  },
]

export const durationUnits = [
  { name: 'Hour(s)', value: 'hour' },
  { name: 'Day(s)', value: 'day' },
  { name: 'Month(s)', value: 'month' },
  { name: 'Week(s)', value: 'week' },
]

export const instructions = [
  { name: 'Before meals', value: 'beforemeals' },
  { name: 'Empty stomach', value: 'emptystomach' },
  { name: 'After meals', value: 'aftermeals' },
  { name: 'In the morning', value: 'inmorning' },
  { name: 'In the evening', value: 'inevening' },
  { name: 'At bedtime', value: 'bedtime' },
  { name: 'Immediately', value: 'immediately' },
  { name: 'As directed', value: 'directed' },
]

export const treatmentsText = {
  add: 'Add',
  lunch: 'Lunch',
  remove: 'Remove',
  select: 'Select',
  dinner: 'Dinner',
  breakfast: 'Breakfast',
  startingFrom: 'Starting From',
  noOptionsFound: 'No Options Found',
  selectAnOption: 'Select an options',
}

export const layout = {
  default: 'Default',
  patientData: 'Patient Data',
  intensivists: 'Intensivists',
  selectLayout: 'Select Layout',
  patientVitals: 'Patient Vitals',
  patientDashboard: 'Patient Dashboard',
  forwardSlash: '/',
}

export const ambulanceText = {
  bpm: 'bpm',
  pro: 'pro',
  spo2: 'SpO2',
  rithm: 'RiTHM',
  fromECG: 'From ECG',
  systolic: 'Systolic',
  skinTemp: 'Skin Temp',
  lenError: 'len_error',
  diastolic: 'Diastolic',
  heartRate: 'Heart Rate',
  bloodPressure: 'Blood Pressure',
  respiratoryRate: 'Respiratory Rate',
  processingFromDevice: 'Processing from device',
  processingFromECG: 'Processing from ECG & PPG',
  noAmbulanceAvailableText: 'No ambulances available',
  noLiveFeedText:
    'Live Feed Unavailable. Please refresh the page or select a different device.',
}

export const patientDashboard = {
  bp: 'BP',
  art: 'ART',
  ecg: 'ECG',
  name: 'Name',
  spo2: 'SpO2',
  view: 'View',
  actions: 'Actions',
  critical: 'critical',
  mrNumber: 'MR Number',
  selectWard: 'Select Ward',
  lastVisited: 'Last Visited',
  selectLocation: 'Select Location',
  respiratoryRate: 'Respiratory Rate',
  wardSelection: 'Please select a ward',
  noWardsAvailable: 'No wards available',
  openingPatientInPod: 'Opening patient in POD...',
  patientNotFoundInPod: 'Unable to open. Patient not found in POD',
  loadingPatients: 'No patients found for your search query/location',
  refreshPageMessage: 'Please refresh the page to receive real-time data.',
}

export const historyVitals = {
  spo2: 'SpO2',
  systolicBP: 'Systolic BP',
  diastolicBP: 'Diastolic BP',
  ecgHeartRate: 'ECG Heart Rate',
  respiratoryRate: 'Respiratory Rate',
  arterialSystolic: 'Arterial Systolic',
  arterialDiastolic: 'Arterial Diastolic',
  eightHoursInterval: 'Duration: 8Hrs, Interval: 15Mins',
}

export const historyLayouts = {
  spo2: 'spo2',
  systolicBP: 'systolicBP',
  diastolicBP: 'diastolicBP',
  artSystolic: 'artSystolic',
  artDiastolic: 'artDiastolic',
  ecgHeartRate: 'ecgHeartRate',
  respiratoryValue: 'respiratoryValue',
}

export const historyCharts = {
  ecgHeartRate: 'ECG Heart Rate',
  ecgHeartRateCode: 'ecgHeartRate',
  systolicBP: 'Systolic BP',
  systolicBpValue: 'systolicBpValue',
  diastolicBP: 'Diastolic BP',
  diastolicBpValue: 'diastolicBpValue',
  spo2: 'SPO2',
  spo2Value: 'spo2',
  respiratoryValue: 'Respiratory Value',
  respiratoryValueCode: 'respiratoryValue',
  systolicBpLayout: 'systolicBP',
  diastolicBpLayout: 'diastolicBP',
  arterialSys: 'artSystolic',
  arterialDia: 'artDiastolic',
  artSystolic: 'Arterial Systolic',
  artDiastolic: 'Arterial Diastolic',
  currentlyViewing: 'Currently viewing:',
  none: 'None',
  arterialSysValue: 'arterialSys',
  arterialDiaValue: 'arterialDia',
}

export const videoCall = {
  or: 'OR',
  pod1: 'POD 1',
  pod2: 'POD 2',
  selectRoom: 'Select a room',
  selectARoom: 'Select a room',
  conferenceRoom: 'Conference Room',
  startVideoCall: 'Start Video Call',
  selectExistingRoom: 'Select existing room',
  enterRoomPlaceholder: 'Enter room name in title case',
  roomNameHelperText:
    'Use complex room names (Min length: 12) like: CommandCenterUrgentCall',
  recordingFeatureUnavailable:
    'Recording facility unavailable. You can still continue with the call.',
}

export const medicationAdministration = {
  route: 'Route',
  dosage: 'Dosage',
  medicine: 'Medicine',
  frequency: 'Frequency',
  duration: 'Duration(units)',
  noData: 'No Medicines for the day',
}

export const allocation = {
  assigned: 'ASSIGNED',
  critical: 'critical',
  occupied: 'OCCUPIED',
  nonCritical: 'non-critical',
}

export const alerts = {
  low: 'Low',
  high: 'High',
  lowerThanLimit: 'Lower than limit',
  higherThanLimit: 'Higher than limit',
  alertsError:
    'Patient details not found, Please check Patient ID in Mindray device',
}

export const hospitals = {
  vitalsLayout: 'vitals',
  defaultLayout: 'default',
  patientDataLayout: 'patientData',
  intensivistsLayout: 'intensivists',
  serachPlaceholder: 'Name,ID,MR Number',
  patientDashboardLayout: 'patientDashboard',
  noneLayout: 'none',
}

export const layoutOptions = [
  {
    value: 'none',
    label: 'Select Layout',
  },
  {
    value: 'patientDashboard',
    label: 'Patient Dashboard',
  },
  {
    value: 'vitals',
    label: 'Patient Vitals',
  },
  {
    value: 'patientData',
    label: 'Patient Data',
  },
  {
    value: 'intensivists',
    label: 'Intensivists',
  },
  {
    value: 'default',
    label: 'Default',
  },
]

export const sidebarTexts = {
  no: 'NO',
  yes: 'YES',
  currentlyLoggedIn: 'Currently logged in as',
  logoutConfirm: 'Are you sure you want to logout?',
}

export const sidebarPatientLayout = {
  vitals: 'vitals',
  default: 'default',
  patientData: 'patientData',
  intensivists: 'intensivists',
}

export const thopNotificationAlertType = {
  careplan: 'careplan',
  statusAccepted: 'statusAccepted',
  statusRejected: 'statusRejected',
  acceptCareplan: 'accept-careplan',
  rejectCareplan: 'reject-careplan',
}

export const ChartDataType = {
  ecg: 'ecg',
  resp: 'resp',
  pulse: 'pulse',
  bloodPressure: 'bloodPressure',
}

export const channelsType = {
  resp: 'Resp',
  pleth: 'Pleth',
  bloodPressure: 'Blood pressure',
  electrocardiogram: 'Electrocardiogram',
}

export const noResponseData = {
  hyphenString: '-',
  emptyString: '',
  emptyArray: [],
}

export const careplanInfo = {
  medications: 'Medications:',
  noMedications: 'No Medications',
  breakfast: 'breakfast',
  lunch: 'lunch',
  dinner: 'dinner',
  beforeBreakfast: 'bb',
  afterBreakfast: 'ab',
  beforeLunch: 'bl',
  afterLunch: 'al',
  beforeDinner: 'bd',
  afterDinner: 'ad',
  before: 'before',
  after: 'after',
  Doctor: ' Doctor:',
  treatmentSuggestions: 'Treatment Suggestions:',
}

export const medication = {
  immediately: 'Immediately',
  onceADay: 'Once a day',
  twiceADay: 'Twice a day',
  fiveTimesADay: 'Five times a day',
  thriceADay: 'Thrice a day',
  fourTimesADay: 'Four times a day',
  hour: 'hour',
  everyTwoWeeks: 'Every 2 weeks',
  everyThreeWeeks: 'Every 3 weeks',
  onAlternateDays: 'On alternate days',
}
